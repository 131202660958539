import {AuthModel} from '../../../requests/models'

const AUTH_LOCAL_STORAGE_KEY = 'auth-react-v'
const AUTH_ERROR_LOCAL_STORAGE_KEY = 'auth-error-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getAuthError = (): {key: string; value: string} | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_ERROR_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const error: {key: string; value: string} = JSON.parse(lsValue) as {key: string; value: string}
    if (error) {
      // You can easily check auth_token expiration also
      return error
    }
  } catch (error) {
    console.error('AUTH ERROR LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuthError = (error: {key: string; value: string}) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(error)
    localStorage.setItem(AUTH_ERROR_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH ERROR LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuthError = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_ERROR_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH ERROR LOCAL STORAGE REMOVE ERROR', error)
  }
}

const setupAxios = (axios: any) => {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {
  setupAxios,
  getAuth,
  setAuth,
  removeAuth,
  getAuthError,
  setAuthError,
  removeAuthError,
  AUTH_LOCAL_STORAGE_KEY,
}

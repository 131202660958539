import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/am'

import enLang from './lang/en.json'
import amLang from './lang/am.json'

import {WithChildren} from '../helpers'

const langTexts = {
  am: amLang,
  en: enLang,
}

const I18nProvider: FC<WithChildren> = ({children}) => {
  const locale = useLang()
  const texts = langTexts[locale]

  return (
    <IntlProvider locale={locale} messages={texts}>
      {children}
    </IntlProvider>
  )
}

export {I18nProvider}

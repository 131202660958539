/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../../helpers'
import {useAuth} from '../../../../../app/modules/auth'
import {useIntl} from 'react-intl'

type Props = {
  className: string
}

function EngageWidget10({className}: Props) {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <div className={`card card-flush ${className}`}>
      <div
        className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
        style={{
          backgroundPosition: '100% 50%',
          backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
        }}
      >
        <div className='mb-10'>
          <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
            <span className='me-2'>
              {`${intl.messages['COMMON.WELCOME']}`}
              <br />
              <span className='position-relative d-inline-block text-danger'>
                <div className='text-danger opacity-75-hover'>
                  {currentUser
                    ? currentUser?.first_name + ' ' + currentUser?.last_name
                    : `${intl.messages['COMMON.USER']}`}
                </div>

                <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
              </span>
            </span>
          </div>
        </div>
        <img
          className='mx-auto h-150px h-lg-200px  theme-light-show'
          src={toAbsoluteUrl('/media/illustrations/misc/upgrade.svg')}
          alt=''
        />
        <img
          className='mx-auto h-150px h-lg-200px  theme-dark-show'
          src={toAbsoluteUrl('/media/illustrations/misc/upgrade-dark.svg')}
          alt=''
        />
      </div>
    </div>
  )
}
export {EngageWidget10}

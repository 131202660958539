/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {useToast} from '../../../../Toast/ToastContext'
import {ApiResponse, ErrorResponse, request} from '../../../requests/api'
import {AuthModel} from '../../../requests/models'
import {AuthRoutes} from '../../../requests/routes'
import {useIntl} from 'react-intl'
import {getAuthError, removeAuthError} from "../core/AuthHelpers";

export function Login() {
  const intl = useIntl()
  // const location = useLocation()
  const {showToast} = useToast()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${intl.messages['YUP.ERROR.INVALID_EMAIL_FORMAT']}`)
      .min(3, `${intl.messages['YUP.ERROR.MIN_3_CHARACTERS']}`)
      .max(50, `${intl.messages['YUP.ERROR.MAX_20_CHARACTERS']}`)
      .required(`${intl.messages['AUTH.ERROR.EMAIL_REQUIRED']}`),
    password: Yup.string()
      .min(3, `${intl.messages['YUP.ERROR.MIN_3_CHARACTERS']}`)
      .max(20, `${intl.messages['YUP.ERROR.MAX_20_CHARACTERS']}`)
      .required(`${intl.messages['AUTH.ERROR.PASSWORD_REQUIRED']}`),
  })

  const initialValues = {
    email: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      request
        .post<ApiResponse<AuthModel>>(AuthRoutes.LOGIN, {
          data: {email: values.email, password: values.password},
        })
        .then(({data}) => {
          saveAuth(data)
          setCurrentUser(data.user)
        })
        .catch((err: ErrorResponse) => {
          err.errors.forEach((error) => {
            if (error && error.key !== '' && formik.getFieldMeta(error.key).value) {
              formik.setFieldError(error.key, error.message)
            } else {
              setStatus(error.message)
            }
          })
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  useEffect(() => {
    let error = getAuthError();
    if (error) {
      showToast(
        `${intl.messages['AUTH.ERROR.AUTHORIZATION']}`,
        `${intl.messages['AUTH.ERROR.TOKEN_EXPIRED']}`,
        'danger'
      )
      removeAuthError();
    }
  }, [])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      onChange={() => {
        if (formik.status) {
          formik.setStatus('')
        }
      }}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>{`${intl.messages['AUTH.SIGN_IN']}`}</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text text-center fw-semibold fs-6'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>{`${intl.messages['AUTH.EMAIL']}`}</label>
        <input
          placeholder={`${intl.messages['AUTH.EMAIL']}`}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{`${intl.messages['AUTH.PASSWORD']}`}</label>
        <input
          placeholder={`${intl.messages['AUTH.PASSWORD']}`}
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mt-10 mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>{`${intl.messages['COMMON.CONTINUE']}`}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {`${intl.messages['COMMON.PLEASE_WAIT']}`}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
